<script setup lang="ts">
defineProps<{ isDelete?: boolean, loading?: boolean }>()
const { isRevealed, cancel, confirm, reveal } = useConfirmDialog()
defineExpose({ reveal })
</script>

<template>
  <UModal
    v-model="isRevealed"
    @close="cancel"
    @keydown.enter.prevent="confirm"
  >
    <div class="p-4">
      <slot />
      <div class="mt-4 flex justify-end">
        <UButton color="gray" @click="cancel">
          Cancel
        </UButton>
        <UButton class="ml-2" :color="isDelete ? 'red' : 'primary'" :loading @click="confirm">
          {{ isDelete ? 'Delete' : 'Confirm' }}
        </UButton>
      </div>
    </div>
  </UModal>
</template>
